<template>
  <div class="auth-wrapper">
    <div class="auth-content">
      <div class="mb-4 text-center">
        <a href="/">
          <img src="../assets/images/logo/logo_dark.png" class="img-fluid" />
        </a>
      </div>

      <div class="card bg-light mb-2">
        <div class="card-block pb-1 text-center shadow-sm">
          <i class="fas fa-hourglass-end fa-4x d-block text-info"></i>
          <div class="mt-3 text-info f-w-300 f-24">
            Oops ! Maintenance en cours...
          </div>
        </div>
        <div class="card-body pt-3">
          {{ message }}
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Footer from "../components/layouts/Footer";

export default {
  name: "PageMaintenance",
  components: { Footer },
  data() {
    return {
      date: new Date()
    };
  },
  computed: {
    ...mapGetters("auth", ["loggedIn"]),
    message() {
      const msg = this.$route.query.message;
      return msg
        ? `${msg}.`
        : "Veuillez patienter le système est en cours de maintenance";
    }
  },
  metaInfo() {
    return {
      title: this.$t(`menu.${this.$route.name}.title`)
    };
  },
  mounted() {
    this.$store.dispatch("checkEndMaintenanceMode").then(({ check }) => {
      if (check) {
        if (this.loggedIn) this.$router.push({ name: "dahsboard" });
        else window.location.href = "/";
      }
    });
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    onNavbarLinkClick() {
      window.$(".navbar-collapse").removeClass("show");
    },
    onNavbarCollapseClick() {
      if (window.$(".navbar-collapse").hasClass("show"))
        window.$(".navbar-collapse").removeClass("show");
      else window.$(".navbar-collapse").addClass("show");
    },
    landingLogout() {
      this.logout(true).then(() => {
        location.reload();
      });
    },
    onTooltipBtn() {
      window.$("body,html").animate(
        {
          scrollTop: 0
        },
        800
      );
    }
  }
};
</script>

<style scoped src="../assets/landing/css/style.css"></style>
